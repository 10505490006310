import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import metadata from "./metadata.json";
import {
  getTravelFromToken,
  postApprovalToken,
} from "./services/approvals.rest";
import formattingError from "./errors/errors.utils";
import Loader from "./components/loader";
import Error from "./components/error";
import Success from "./components/success";
import Travel from "./components/travel";
import Logo from "./assets/img/logo-dark.png";
import Input from "./components/input";
import Button from "./components/button";
import Container from "./components/container"; // Wrapper in order to center everything within page

const { build } = metadata;

// Wrapper in order to center everything within page
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.background};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
`;

const HeaderVersion = styled.div`
  display: flex;
  justify-content: space-between;
  margin: auto;
`;

const HeaderText = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.darkblue};
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid lightgrey;
  border-radius: 8px;
  padding: 2em 1em 1em;
  max-width: 500px;
  gap: 1em;
  width: 100%;
`;

const Img = styled.img`
  width: 100%;
  max-width: 200px;
  margin: auto;
`;

const Info = styled.div`
  display: flex;
  text-align: center;
`;

function App({ configuration, action, token }) {
  // App status
  const [debug] = useState({
    name: process.env.REACT_APP_NAME,
    version: process.env.REACT_APP_VERSION,
    build,
  });

  // Request POST approval status
  const [postStatus, setPostStatus] = useState({
    loading: false,
    status: "pending",
    error: null,
  });

  // Request GET travel status
  const [travelData, setTravelData] = useState({
    loading: false,
    status: "pending",
    error: null,
    data: {},
  });

  const [refusalReason, setRefusalReason] = useState("");

  // Prevent user to quit the page during process
  window.onbeforeunload = (event) => {
    if (postStatus.loading) {
      event.preventDefault(); // standard method to trigger prompt
      return event.returnValue; // compat
    }
    return null;
  };

  const approveOrDenyTravel = useCallback(async () => {
    try {
      setPostStatus({ loading: true, status: "inprogress", error: null });
      await postApprovalToken(configuration, token, refusalReason);
      setPostStatus({ loading: false, status: "completed", error: null });
    } catch (error) {
      setPostStatus({
        loading: false,
        status: "failed",
        error: formattingError(
          error,
          `POST ${configuration.endpoint}${configuration.services.approval}/oneclick/${token}`
        ),
      });
    }
  }, [configuration, token, refusalReason]);

  useEffect(() => {
    const getTravelToApprove = async () => {
      try {
        setTravelData((td) => ({
          data: td.data,
          loading: true,
          status: "inprogress",
          error: null,
        }));
        const response = await getTravelFromToken(configuration, token);
        setTravelData({
          loading: false,
          status: "completed",
          error: null,
          data: response.data.result,
        });
      } catch (error) {
        setTravelData({
          loading: false,
          status: "failed",
          error: formattingError(
            error,
            `GET ${configuration.endpoint}${configuration.services.approval}/oneclick/${token}`
          ),
        });
      }
    };

    getTravelToApprove();
  }, [configuration, token]);

  return (
    <Wrapper data-testid="app">
      <Header data-testid="header">
        <HeaderVersion data-testid="header-debug">
          <HeaderText data-testid="app-name">{`${debug.name} - `}</HeaderText>
          <HeaderText data-testid="app-version">{`Version ${debug.version} - `}</HeaderText>
          <HeaderText data-testid="app-build">{`Build ${debug.build}`}</HeaderText>
        </HeaderVersion>
        <Img src={Logo} alt="The Treep logo" />
      </Header>
      <Card>
        {(postStatus.loading ||
          travelData.loading) && <Loader data-testid="spinner" />}
        {(action === "approve" || action === "deny") &&
          postStatus.status === "completed" && (
            <Success id="approval-success">
              {window.i18(
                navigator.language.split("-")[0],
                action === "approve"
                  ? "TRAVEL_APPROVED_MESSAGE"
                  : "TRAVEL_DENIED_MESSAGE"
              )}
            </Success>
          )}
        {(postStatus.error || travelData.error) && (
          <Error id="approval-error">
            <div>{postStatus?.error?.title ?? travelData?.error?.title}</div>
            <div>
              {postStatus?.error?.message ?? travelData?.error?.message}
            </div>
          </Error>
        )}
        {travelData.status === "completed" &&
          (travelData.data.travel.length === 0 ||
          new Date(travelData.data.ticketTimeLimit) < Date.now() ? (
            <Error id="approval-error">
              <div>
                {window.i18(
                  navigator.language.split("-")[0],
                  "REQUEST_EXPIRED"
                )}
              </div>
            </Error>
          ) : (
            <>
              {!postStatus.loading && !travelData.loading && action === "deny" && postStatus.status === "pending" && (
                <Container width="100%">
                  <Info>
                    {window.i18(navigator.language.split("-")[0], "DENY_INFO")}
                  </Info>
                  <Input
                    value={refusalReason}
                    onChange={(event) => setRefusalReason(event.target.value)}
                    data-testid="refusal-reason"
                    label={window.i18(
                      navigator.language.split("-")[0],
                      "REFUSAL_REASON_LABEL"
                    )}
                    placeholder={window.i18(
                      navigator.language.split("-")[0],
                      "REFUSAL_REASON_PLACEHOLDER"
                    )}
                  />
                  <Button
                    data-testid="refuse-button"
                    onClick={approveOrDenyTravel}
                  >
                    {window.i18(navigator.language.split("-")[0], "REFUSE")}
                  </Button>
                </Container>
              )}
              {!postStatus.loading && !travelData.loading && action === "approve" && postStatus.status === "pending" && (
                <Container width="100%">
                  <Info>
                    {window.i18(
                      navigator.language.split("-")[0],
                      "CONFIRM_INFO"
                    )}
                  </Info>
                  <Button
                    data-testid="accept-button"
                    onClick={approveOrDenyTravel}
                  >
                    {window.i18(navigator.language.split("-")[0], "ACCEPT")}
                  </Button>
                </Container>
              )}
              <Travel travelData={travelData.data} />
            </>
          ))}
      </Card>
    </Wrapper>
  );
}

App.displayName = "App";
App.propTypes = {
  action: PropTypes.oneOf(["approve", "deny"]).isRequired,
  token: PropTypes.string.isRequired,
  configuration: PropTypes.shape({
    endpoint: PropTypes.string.isRequired,
    services: PropTypes.shape({
      approval: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default App;
